import React from 'react';
import { useSession } from '@napa/react-core';
import { Navigate } from 'react-router-dom';
import { DEFAULT_LOGGED_IN_ROUTE } from 'utils/url';
import { MAINTAIN_COMPETITIONS_CLAIM } from 'utils/claims';

interface RedirectWhenNotMaintainCompetitionsProps {
  children: React.JSX.Element;
}

export function RedirectWhenNotMaintainCompetitions(
  props: RedirectWhenNotMaintainCompetitionsProps
): React.JSX.Element {
  const { children } = props;
  const { getClaim } = useSession();
  const canMaintain = !!getClaim(MAINTAIN_COMPETITIONS_CLAIM);
  return canMaintain ? children : <Navigate to={DEFAULT_LOGGED_IN_ROUTE} replace />;
}
