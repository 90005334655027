import React from 'react';
import { useSession } from '@napa/react-core';
import { Navigate } from 'react-router-dom';
import { DEFAULT_LOGGED_IN_ROUTE } from 'utils/url';

interface RedirectWhenNotMaintainVinroomProps {
  children: React.JSX.Element;
}

export function RedirectWhenNotMaintainVinroom(props: RedirectWhenNotMaintainVinroomProps): React.JSX.Element {
  const { children } = props;
  const { isLoggedIn } = useSession();
  return isLoggedIn ? children : <Navigate to={DEFAULT_LOGGED_IN_ROUTE} replace />;
}
