import { PointOfSaleConfiguration } from '@napa/vinroom-core';
import { UseBaseMutationResult } from 'react-query';
import { CustomHookProps, useBasicMutation } from '@napa/react-core';

export function useSavePointOfSaleConfiguration(
  props: Partial<CustomHookProps<PointOfSaleConfiguration>> = {}
): UseBaseMutationResult<PointOfSaleConfiguration, any, any, any> {
  return useBasicMutation<PointOfSaleConfiguration>({
    apiRoute: 'PointOfSale/Configurations',
    httpMethod: 'POST',
    isFormData: false,
    ...props
  });
}
