import * as React from 'react';
import { ApiErrorHandlerProvider, useSession } from '@napa/react-core';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';

interface ApiErrorHandlerProps {
  children?: React.ReactElement;
}

export const ApiErrorHandler: React.FC<ApiErrorHandlerProps> = (props: ApiErrorHandlerProps) => {
  const { children } = props;
  const toast = useToast();
  const navigate = useNavigate();
  const { logOut } = useSession();
  const queryClient = useQueryClient();

  return (
    <ApiErrorHandlerProvider
      onServerError={(): void => {
        toast({
          duration: 3000,
          isClosable: true,
          position: 'top-right',
          status: 'error',
          title: 'Uh Oh! An error has occurred.'
        });
      }}
      onClientError={(response: Response): void => {
        if (response.status === 401) {
          logOut(() => {
            navigate('/');
            queryClient.clear();
          });
        } else if (response.status === 403 || response.status === 404) {
          navigate('/404');
        } else if (response.status === 405) {
          toast({
            duration: 3000,
            isClosable: true,
            position: 'top-right',
            status: 'error',
            title: 'Uh Oh! An error has occurred.'
          });
        }
      }}
    >
      {children ?? <></>}
    </ApiErrorHandlerProvider>
  );
};
