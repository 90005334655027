import React from 'react';
import { useSession } from '@napa/react-core';
import { Navigate, useLocation } from 'react-router-dom';
import {
  MAINTAIN_VINROOM_CLAIM,
  MAINTAIN_WINERIES_CLAIM,
  MAINTAIN_CARTS_CLAIM,
  MAINTAIN_COMPETITIONS_CLAIM
} from 'utils/claims';

interface RedirectWhenLoggedOutProps {
  children: React.JSX.Element;
  redirectTo: string;
}

export function RedirectWhenLoggedOut(props: RedirectWhenLoggedOutProps): React.JSX.Element {
  const { children, redirectTo } = props;
  const { getClaim, isLoggedIn, logOut } = useSession();

  // User that cannot log in
  if (
    !getClaim(MAINTAIN_VINROOM_CLAIM) &&
    !getClaim(MAINTAIN_WINERIES_CLAIM) &&
    !getClaim(MAINTAIN_CARTS_CLAIM) &&
    !getClaim(MAINTAIN_COMPETITIONS_CLAIM)
  ) {
    logOut();
  }

  const { pathname } = useLocation();
  return isLoggedIn ? (
    children
  ) : (
    <Navigate
      to={`${redirectTo}?mode=login&message=NotLoggedIn&referrer=${pathname}${encodeURIComponent(
        window.location.search
      )}`}
      replace
    />
  );
}
