import { CustomHookProps, useBasicQuery } from '@napa/react-core';
import { Cart } from 'types/carts';
import { PointOfSaleSystem } from '@napa/vinroom-core';

export const POINT_OF_SALE_SYSTEMS_KEY = 'POINT_OF_SALE_SYSTEMS_KEY';

export function useGetPointOfSaleSystems(props: Partial<CustomHookProps<PointOfSaleSystem>> = {}): any {
  return useBasicQuery<Cart>({
    apiRoute: `PointOfSale/Systems`,
    httpMethod: 'GET',
    isFormData: false,
    body: undefined,
    queryId: [POINT_OF_SALE_SYSTEMS_KEY],
    ...props
  });
}
