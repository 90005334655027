import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { NapaChakraProvider } from '@napa/ui-react-chakra';
import { ApiErrorHandler } from './providers/apiErrorHandler';
import RoutesContainer from './routes/RoutesContainer';
import { theme } from './theme';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { AppSettingsProvider, getAppSettings, SessionProvider, SessionRefreshProvider } from '@napa/react-core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TestEnvironmentBanner } from '@napa/ui-react';
import { IntlProvider } from 'react-intl';

const appSettings = getAppSettings();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

library.add(fal);

function App() {
  return (
    <Router>
      <AppSettingsProvider environment={appSettings}>
        <SessionProvider>
          <IntlProvider locale="en">
            <DndProvider backend={HTML5Backend}>
              <NapaChakraProvider theme={theme}>
                <QueryClientProvider client={queryClient} contextSharing={true}>
                  <ApiErrorHandler>
                    <SessionRefreshProvider appSettings={appSettings}>
                      <TestEnvironmentBanner value={appSettings.testEnvironmentBanner} />
                      <RoutesContainer />
                    </SessionRefreshProvider>
                  </ApiErrorHandler>
                </QueryClientProvider>
              </NapaChakraProvider>
            </DndProvider>
          </IntlProvider>
        </SessionProvider>
      </AppSettingsProvider>
    </Router>
  );
}

export default App;
