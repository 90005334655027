export const theme = {
  styles: {
    global: {
      'html, body': {
        bgColor: 'gray.50',
        fontSize: '100%'
      },
      a: {
        color: 'teal.500'
      }
    }
  },
  components: {
    Checkbox: {
      baseStyle: {
        container: {
          alignItems: 'flex-start'
        },
        control: {
          position: 'relative',
          top: 1
        }
      }
    },
    Slider: {
      variants: {
        characteristics: {
          filledTrack: {
            bg: 'transparent'
          },
          thumb: {
            bg: 'blue.500'
          },
          track: {
            bgGradient: 'linear(to-r, cyan.300, cyan.800)'
          }
        }
      }
    }
  }
};
