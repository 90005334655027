import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RedirectWhenLoggedIn } from './RedirectWhenLoggedIn';
import { RedirectWhenLoggedOut } from './RedirectWhenLoggedOut';
import { DEFAULT_LOGGED_IN_ROUTE, DEFAULT_LOGGED_OUT_ROUTE } from 'utils/url';
import { RedirectWhenNotMaintainVinroom } from './RedirectWhenNotMaintainVinroom';
import { RedirectWhenNotMaintainCompetitions } from './RedirectWhenNotMaintainCompetitions';
import WineryEditPointOfSaleConfiguration from '../../domains/wineries/containers/EditWineryContainer/PointOfSale';

const AdminUserBrowseContainer = lazy(() => import('containers/adminUsers/AdminUserBrowseContainer'));
const AdminUserCard = lazy(() => import('components/adminUsers/AdminUserCard'));
const AdminUserList = lazy(() => import('components/adminUsers/AdminUserList'));
const ChangePasswordContainer = lazy(() => import('containers/ChangePasswordContainer'));
const ClientConfigsForm = lazy(() => import('components/settings/ClientConfigsForm'));
const CreateAdminUserContainer = lazy(() => import('containers/adminUsers/CreateAdminUserContainer'));
const CreateTastingContainer = lazy(() => import('containers/tastings/CreateTastingContainer'));
const CreateWineryContainer = lazy(() => import('domains/wineries/containers/CreateWineryContainer'));
const EditAdminUserContainer = lazy(() => import('containers/adminUsers/EditAdminUserContainer'));
const EditTastingContainer = lazy(() => import('containers/tastings/EditTastingContainer'));
const EditWineryDetailContainer = lazy(() => import('domains/wineries/containers/EditWineryContainer/Detail'));
const ErrorHandlerArea = lazy(() => import('components/ErrorHandlerArea'));
const ErrorHandlerContainer = lazy(() => import('containers/ErrorHandlerContainer'));
const ForgotPasswordCard = lazy(() => import('components/ForgotPasswordCard'));
const ForgotPasswordContainer = lazy(() => import('containers/ForgotPasswordContainer'));
const LoginCard = lazy(() => import('components/LoginCard'));
const LoginContainer = lazy(() => import('containers/LoginContainer'));
const NotFoundArea = lazy(() => import('components/vinroom/NotFoundArea'));
const NotFoundContainer = lazy(() => import('containers/vinroom/NotFoundContainer'));
const PasswordManagementCard = lazy(() => import('components/PasswordManagementCard'));
const ReportsContainer = lazy(() => import('domains/reports/containers/ReportsContainer'));
const ResetPasswordContainer = lazy(() => import('containers/ResetPasswordContainer'));
const SettingsFrameContainer = lazy(() => import('containers/settings/SettingsFrameContainer'));
const TastingCard = lazy(() => import('components/tastings/TastingCard'));
const TastingList = lazy(() => import('components/tastings/TastingList'));
const UserProfileContainer = lazy(() => import('containers/ProfileContainer'));
const UserProfileFrameContainer = lazy(() => import('containers/userProfile/UserProfileFrameContainer'));
const VinroomFrameContainer = lazy(() => import('containers/vinroom/VinroomFrameContainer'));
const WineDetailCard = lazy(() => import('components/wines/WineDetailCard'));
const WineRecommendationsContainer = lazy(() => import('containers/settings/WineRecommendationsContainer'));
const WineList = lazy(() => import('components/wines/WineList'));
const WineFlavorsBrowseContainer = lazy(() => import('containers/settings/WineFlavorsBrowseContainer'));
const WineFlavorsCreateContainer = lazy(() => import('containers/settings/WineFlavorsCreateContainer'));
const WineFlavorsEditContainer = lazy(() => import('containers/settings/WineFlavorsEditContainer'));
const WineFlavorList = lazy(() => import('components/wines/WineFlavorList'));
const WineFlavorForm = lazy(() => import('components/wines/WineFlavorForm'));
const WineGrapesBrowseContainer = lazy(() => import('containers/settings/WineGrapesBrowseContainer'));
const WineGrapesCreateContainer = lazy(() => import('containers/settings/WineGrapesCreateContainer'));
const WineGrapesEditContainer = lazy(() => import('containers/settings/WineGrapesEditContainer'));
const WineGrapeList = lazy(() => import('components/wines/WineGrapeList'));
const WineGrapeForm = lazy(() => import('components/wines/WineGrapeForm'));
const WinePairingsBrowseContainer = lazy(() => import('containers/settings/WinePairingsBrowseContainer'));
const WinePairingsCreateContainer = lazy(() => import('containers/settings/WinePairingsCreateContainer'));
const WinePairingsEditContainer = lazy(() => import('containers/settings/WinePairingsEditContainer'));
const WinePairingList = lazy(() => import('components/wines/WinePairingList'));
const WinePairingForm = lazy(() => import('components/wines/WinePairingForm'));
const WineTypesBrowseContainer = lazy(() => import('containers/settings/WineTypesBrowseContainer'));
const WineTypesCreateContainer = lazy(() => import('containers/settings/WineTypesCreateContainer'));
const WineTypesEditContainer = lazy(() => import('containers/settings/WineTypesEditContainer'));
const WineTypeList = lazy(() => import('components/wines/WineTypeList'));
const WineTypeForm = lazy(() => import('components/wines/WineTypeForm'));
const WineryBrowseContainer = lazy(() => import('domains/wineries/containers/WineryBrowseContainer'));
const WineryCartBrowseContainer = lazy(() => import('domains/wineries/containers/EditWineryContainer/Carts'));
const WineryEditFrameContainer = lazy(() => import('domains/wineries/containers/WineryEditFrameContainer'));
const WineryEventCreateContainer = lazy(() => import('domains/wineries/containers/EditWineryContainer/EventCreate'));
const WineryEventDetailEditContainer = lazy(
  () => import('domains/wineries/containers/EditWineryContainer/EventDetail')
);
const WineryEventListBrowseContainer = lazy(() => import('domains/wineries/containers/EditWineryContainer/Events'));
const WineryFeaturesBrowseContainer = lazy(() => import('containers/settings/WineryFeaturesBrowseContainer'));
const WineryFeaturesCreateContainer = lazy(() => import('containers/settings/WineryFeaturesCreateContainer'));
const WineryFeaturesEditContainer = lazy(() => import('containers/settings/WineryFeaturesEditContainer'));
const WineryMailingListBrowseContainer = lazy(
  () => import('domains/wineries/containers/EditWineryContainer/MailingList')
);
const WineryTastingsBrowseContainer = lazy(() => import('domains/wineries/containers/EditWineryContainer/Tastings'));
const WineryWinesBrowseContainer = lazy(() => import('domains/wineries/containers/EditWineryContainer/Wines'));
const WineryWinesCreateContainer = lazy(() => import('containers/wines/CreateWineContainer'));
const WineryWinesEditContainer = lazy(() => import('containers/wines/EditWineContainer'));
const CartCard = lazy(() => import('components/carts/CartCard'));
const CartEditContainer = lazy(() => import('containers/carts/EditCartContainer'));
const CompetitionsBrowseContainer = lazy(() => import('containers/competitions/CompetitionsBrowseContainer'));
const CompetitionsList = lazy(() => import('components/competitions/CompetitionList'));
const EditCompetitionsContainer = lazy(() => import('containers/competitions/EditCompetitionContainer'));
const CompetitionDetailForm = lazy(() => import('components/competitions/CompetitionDetailForm'));

export default function RoutesContainer(): React.JSX.Element {
  return (
    <Suspense>
      <Routes>
        <Route
          path={'/'}
          element={
            <RedirectWhenLoggedIn redirectTo={DEFAULT_LOGGED_IN_ROUTE}>
              <VinroomFrameContainer hideNavigation maxWidth="container.sm">
                <LoginContainer loginComponent={<LoginCard />} />
              </VinroomFrameContainer>
            </RedirectWhenLoggedIn>
          }
        />
        <Route
          path={'/forgotPassword'}
          element={
            <RedirectWhenLoggedIn redirectTo={DEFAULT_LOGGED_IN_ROUTE}>
              <VinroomFrameContainer hideNavigation maxWidth="container.sm">
                <ForgotPasswordContainer forgotPasswordComponent={<ForgotPasswordCard />} />
              </VinroomFrameContainer>
            </RedirectWhenLoggedIn>
          }
        />
        <Route
          path={'/resetPassword'}
          element={
            <RedirectWhenLoggedIn redirectTo={DEFAULT_LOGGED_IN_ROUTE}>
              <VinroomFrameContainer hideNavigation maxWidth="container.sm">
                <ResetPasswordContainer resetPasswordComponent={<PasswordManagementCard />} />
              </VinroomFrameContainer>
            </RedirectWhenLoggedIn>
          }
        />
        <Route
          path={'/adminUsers/browse'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="adminusers">
                  <AdminUserBrowseContainer adminUserListComponent={<AdminUserList />} />
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/adminUsers/changePassword'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="profile" maxWidth="container.sm">
                <UserProfileFrameContainer activeTab="changePassword">
                  <ChangePasswordContainer changePasswordComponent={<PasswordManagementCard />} />
                </UserProfileFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/adminUsers/userProfile'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="profile" maxWidth="container.sm">
                <UserProfileFrameContainer activeTab="userProfile">
                  <UserProfileContainer userProfileComponent={<AdminUserCard />} />
                </UserProfileFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/adminUsers/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="adminusers" maxWidth="container.sm">
                  <CreateAdminUserContainer adminUserDetailsComponent={<AdminUserCard />} />
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/adminUsers/:userId'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="adminusers" maxWidth="container.sm">
                  <EditAdminUserContainer adminUserDetailsComponent={<AdminUserCard />} />
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/reports'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <ReportsContainer />
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="flavors">
                    <WineFlavorsBrowseContainer component={<WineFlavorList />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/flavors'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="flavors">
                    <WineFlavorsBrowseContainer component={<WineFlavorList />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/flavors/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="flavors">
                    <WineFlavorsCreateContainer component={<WineFlavorForm />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/flavors/:wineFlavorId'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="flavors">
                    <WineFlavorsEditContainer component={<WineFlavorForm />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/grapes'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="grapes">
                    <WineGrapesBrowseContainer component={<WineGrapeList />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/grapes/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="grapes">
                    <WineGrapesCreateContainer component={<WineGrapeForm />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/grapes/:wineGrapeId'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="grapes">
                    <WineGrapesEditContainer component={<WineGrapeForm />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/pairings'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="pairings">
                    <WinePairingsBrowseContainer component={<WinePairingList />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/pairings/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="pairings">
                    <WinePairingsCreateContainer component={<WinePairingForm />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/pairings/:winePairingId'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="pairings">
                    <WinePairingsEditContainer component={<WinePairingForm />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/recommendations'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="recommendations">
                    <WineRecommendationsContainer component={<ClientConfigsForm />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/types'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="types">
                    <WineTypesBrowseContainer component={<WineTypeList />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/types/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="types">
                    <WineTypesCreateContainer component={<WineTypeForm />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/types/:wineTypeId'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="types">
                    <WineTypesEditContainer component={<WineTypeForm />} />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/features'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="features">
                    <WineryFeaturesBrowseContainer />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/features/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="features">
                    <WineryFeaturesCreateContainer />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/settings/wines/features/:wineryFeatureId'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainVinroom>
                <VinroomFrameContainer activeNavbarButton="settings">
                  <SettingsFrameContainer activeTab="features">
                    <WineryFeaturesEditContainer />
                  </SettingsFrameContainer>
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainVinroom>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/browse'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryBrowseContainer />
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <CreateWineryContainer />
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/details'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="details">
                  <EditWineryDetailContainer />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/mailingList'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="mailinglist">
                  <WineryMailingListBrowseContainer />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/events'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="events">
                  <WineryEventListBrowseContainer />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/events/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="events">
                  <WineryEventCreateContainer />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/events/:wineryEventId'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="events">
                  <WineryEventDetailEditContainer />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/pos'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="pos">
                  <WineryEditPointOfSaleConfiguration />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/wines'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="wines">
                  <WineryWinesBrowseContainer wineryWinesBrowseComponent={<WineList />} />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/tastings'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="tastings">
                  <WineryTastingsBrowseContainer wineryTastingsBrowseComponent={<TastingList />} />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/carts'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="carts">
                  <WineryCartBrowseContainer />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/wines/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="wines">
                  <WineryWinesCreateContainer />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/:wineryId/wines/:wineId'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="wines">
                  <WineryWinesEditContainer wineryWinesEditComponent={<WineDetailCard />} />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/edit/:wineryId/tastings/create'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="tastings">
                  <CreateTastingContainer />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/edit/:wineryId/tastings/:tastingId/edit'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="tastings">
                  <EditTastingContainer tastingDetailsComponent={<TastingCard />} />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/edit/:wineryId/carts/:cartId/edit'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="carts">
                  <CartEditContainer cartDetailsComponent={<CartCard />} />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/wineries/edit/:wineryId/carts/number/:cartNumber/edit'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <VinroomFrameContainer activeNavbarButton="wineries" maxWidth="container.lg">
                <WineryEditFrameContainer activeTab="carts">
                  <CartEditContainer cartDetailsComponent={<CartCard />} />
                </WineryEditFrameContainer>
              </VinroomFrameContainer>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/competitions/browse'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainCompetitions>
                <VinroomFrameContainer activeNavbarButton="competitions">
                  <CompetitionsBrowseContainer competitionsBrowseComponent={<CompetitionsList />} />
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainCompetitions>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/competitions/edit/:competitionId'}
          element={
            <RedirectWhenLoggedOut redirectTo={DEFAULT_LOGGED_OUT_ROUTE}>
              <RedirectWhenNotMaintainCompetitions>
                <VinroomFrameContainer activeNavbarButton="competitions">
                  <EditCompetitionsContainer editCompetitionComponent={<CompetitionDetailForm />} />
                </VinroomFrameContainer>
              </RedirectWhenNotMaintainCompetitions>
            </RedirectWhenLoggedOut>
          }
        />
        <Route
          path={'/error'}
          element={
            <VinroomFrameContainer hideNavigation>
              <ErrorHandlerContainer errorHandlerComponent={<ErrorHandlerArea />} />
            </VinroomFrameContainer>
          }
        />
        <Route
          path="/404"
          element={
            <VinroomFrameContainer hideNavigation>
              <NotFoundContainer notFoundComponent={<NotFoundArea />} />
            </VinroomFrameContainer>
          }
        />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Suspense>
  );
}
