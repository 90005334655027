import React from 'react';
import { useSession } from '@napa/react-core';
import { Navigate } from 'react-router-dom';

interface RedirectWhenLoggedInProps {
  children: React.JSX.Element;
  redirectTo: string;
}

export function RedirectWhenLoggedIn(props: RedirectWhenLoggedInProps): React.JSX.Element {
  const { children, redirectTo } = props;
  const { isLoggedIn } = useSession();
  return isLoggedIn ? <Navigate to={redirectTo} replace /> : children;
}
