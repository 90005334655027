import { CustomHookProps, useBasicQuery } from '@napa/react-core';
import { Cart } from 'types/carts';
import { PointOfSaleConfiguration } from '@napa/vinroom-core';

interface UseGetPointOfSaleConfigurationProps extends CustomHookProps<PointOfSaleConfiguration> {
  wineryId: string;
}

export const GET_POINT_OF_SALE_CONFIGURATION = 'GET_POINT_OF_SALE_CONFIGURATION';

export function useGetPointOfSaleConfiguration(props: Partial<UseGetPointOfSaleConfigurationProps> = {}): any {
  const { wineryId, ...params } = props;
  return useBasicQuery<Cart>({
    apiRoute: `PointOfSale/Configurations/${wineryId}`,
    httpMethod: 'GET',
    isFormData: false,
    body: undefined,
    queryId: [GET_POINT_OF_SALE_CONFIGURATION, wineryId],
    ...params
  });
}
