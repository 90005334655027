import { Button, HStack, Input, Select, VStack } from '@chakra-ui/react';
import { handleFormDataReset, handleServerHookFormValidation } from '@napa/react-core';
import { WebForm, WebFormControl } from '@napa/ui-react-chakra';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { VinroomSubmitButton } from 'buttons/Submit';
import { PointOfSaleConfiguration, PointOfSaleSystem } from '@napa/vinroom-core';

interface WineryPointOfSaleConfigurationFormProps {
  configuration: PointOfSaleConfiguration;
  deleteAction: () => void;
  isDeleting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  pointOfSaleSystems: Array<PointOfSaleSystem>;
  serverValidation: any;
  submitAction: (form: PointOfSaleConfiguration) => void;
}

export default function WineryPointOfSaleConfigurationForm(
  props: WineryPointOfSaleConfigurationFormProps
): React.JSX.Element {
  const {
    configuration,
    deleteAction = () => undefined,
    isDeleting,
    isLoading,
    isSaving,
    pointOfSaleSystems = [],
    serverValidation,
    submitAction = () => undefined
  } = props;

  const { register, setFocus, reset, handleSubmit, formState, setError } = useForm<PointOfSaleConfiguration>({
    defaultValues: {
      id: configuration?.id ?? '',
      pointOfSaleSystemId: configuration?.pointOfSaleSystemId ?? '',
      tenant: configuration?.tenant ?? '',
      wineryId: configuration?.wineryId ?? ''
    }
  });

  React.useEffect((): void => {
    handleServerHookFormValidation(serverValidation, setError);
  }, [serverValidation, setError]);

  React.useEffect((): void => {
    handleFormDataReset(
      {
        id: configuration?.id ?? '',
        pointOfSaleSystemId: configuration?.pointOfSaleSystemId ?? '',
        tenant: configuration?.tenant ?? '',
        wineryId: configuration?.wineryId ?? ''
      },
      reset
    );
    // For some reason, the wine form scrolled down once form data was reset, so applying the same here.
    setTimeout((): void => {
      window.scrollTo(0, 0);
    }, 20);
  }, [configuration, reset, setFocus]);

  return (
    <WebForm
      autoComplete="off"
      display="flex"
      flexDirection="column"
      gap={4}
      onSubmit={handleSubmit(submitAction)}
      width="100%"
    >
      <VStack pb={6} px={6} pt={3} gap={3} width="100%">
        <WebFormControl
          defaultValue=""
          errorMessage={formState.errors.pointOfSaleSystemId?.message}
          labelText="Point of Sale System"
        >
          <Select {...register('pointOfSaleSystemId')} placeholder="Select">
            {pointOfSaleSystems.map((system: PointOfSaleSystem) => (
              <option key={system.id} value={system.id}>
                {system.name}
              </option>
            ))}
          </Select>
        </WebFormControl>
        <WebFormControl errorMessage={formState.errors.tenant?.message} labelText="Tenant">
          <Input {...register('tenant')} type="text" />
        </WebFormControl>
        <HStack gap={3} pt={3} width="100%">
          <Button
            colorScheme="red"
            variant="solid"
            isDisabled={isLoading}
            isLoading={isDeleting}
            onClick={deleteAction}
            width="100%"
          >
            Delete
          </Button>
          <VinroomSubmitButton isDisabled={isLoading} isLoading={isSaving} width="100%" />
        </HStack>
      </VStack>
    </WebForm>
  );
}
