import React, { Ref } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export interface VinroomSubmitButtonProps extends ButtonProps {
  ref?: Ref<HTMLButtonElement>;
  children?: React.ReactNode;
}

export function VinroomSubmitButton(props: Readonly<VinroomSubmitButtonProps>): React.ReactElement {
  const { children = 'Save', ref, ...otherProps } = props;
  return (
    <Button colorScheme="napaShamrock" ref={ref} variant="solid" type="submit" {...otherProps}>
      {children}
    </Button>
  );
}
