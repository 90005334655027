import { AlertStatus, useToast } from '@chakra-ui/react';
import React from 'react';

const duration = 4500;
const position = 'top-right';
const isClosable = true;

interface VinroomToastProps {
  name: string;
}

interface VinroomToastState {
  createError: (message?: string) => void;
  createSuccess: (message?: string) => void;
  deleteError: (message?: string) => void;
  deleteSuccess: (message?: string) => void;
  fetchError: (message?: string) => void;
  editError: (message?: string) => void;
  editSuccess: (message?: string) => void;
  sentError: (message?: string) => void;
  sentSuccess: (message?: string) => void;
}

export function useVinroomToast(props: VinroomToastProps): VinroomToastState {
  const { name = '' } = props;
  const toast = useToast();

  const show = React.useCallback(
    (title: string, status: AlertStatus = 'success'): void => {
      toast({
        title,
        status,
        duration,
        position,
        isClosable
      });
    },
    [toast]
  );

  return {
    createError: (message?: string) =>
      show(message ?? `Uh oh! We can't add your ${name.toLowerCase()} right now.`, 'error'),
    createSuccess: (message?: string) => show(message ?? `Noted!`),
    deleteError: (message?: string) =>
      show(message ?? `Uh oh! We can't delete your ${name.toLowerCase()} right now.`, 'error'),
    deleteSuccess: (message?: string) => show(message ?? `Gone!`),
    fetchError: (message?: string) =>
      show(message ?? `Uh oh! We can't show the ${name.toLowerCase()} right now.`, 'error'),
    editError: (message?: string) =>
      show(message ?? `Uh oh! We can't save your ${name.toLowerCase()} right now.`, 'error'),
    editSuccess: (message?: string) => show(message ?? `Noted!`),
    sentError: (message?: string) =>
      show(message ?? `Uh oh! We can't send the ${name.toLowerCase()} right now.`, 'error'),
    sentSuccess: (message?: string) => show(message ?? `${name} sent smoothly.`)
  };
}
