import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  GET_POINT_OF_SALE_CONFIGURATION,
  useGetPointOfSaleConfiguration
} from '../../../hooks/useGetPointOfSaleConfiguration';
import WineryPointOfSaleConfigurationForm from '../../../components/WineryPointOfSaleConfigurationForm';
import { useGetPointOfSaleSystems } from '../../../hooks/useGetPointOfSaleSystems';
import { useSavePointOfSaleConfiguration } from '../../../hooks/useSavePointOfSaleConfiguration';
import { useDeletePointOfSaleConfiguration } from '../../../hooks/useDeletePointOfSaleConfiguration';
import { useQueryClient } from 'react-query';
import { useVinroomToast } from '../../../../../utils/toast';

export default function WineryEditPointOfSaleConfiguration(): React.JSX.Element {
  const { wineryId = '' } = useParams();
  const { data: configuration, isLoading } = useGetPointOfSaleConfiguration({ wineryId });
  const { data: pointOfSaleSystems } = useGetPointOfSaleSystems();
  const deleteConfiguration = useDeletePointOfSaleConfiguration();
  const saveConfiguration = useSavePointOfSaleConfiguration();
  const queryClient = useQueryClient();
  const toast = useVinroomToast({ name: 'POS Configuration' });

  const deleteAction = useCallback(async (): Promise<void> => {
    try {
      const response: any = await deleteConfiguration.mutateAsync({
        id: configuration?.id
      });
      if (response && !response.validationErrors) {
        await queryClient.invalidateQueries([GET_POINT_OF_SALE_CONFIGURATION, wineryId]);
        toast.editSuccess();
      } else {
        toast.editError();
      }
    } catch (e) {
      toast.editError();
    }
  }, [configuration?.id, deleteConfiguration, queryClient, toast, wineryId]);

  const submitAction = useCallback(
    async (formData: any): Promise<void> => {
      try {
        const response: any = await saveConfiguration.mutateAsync({
          id: formData.id || undefined,
          pointOfSaleSystemId: formData.pointOfSaleSystemId || undefined,
          tenant: formData.tenant,
          wineryId
        });
        if (response && !response.validationErrors) {
          await queryClient.invalidateQueries([GET_POINT_OF_SALE_CONFIGURATION, wineryId]);
          toast.editSuccess();
        } else {
          toast.editError();
        }
      } catch (e) {
        toast.editError();
      }
    },
    [queryClient, saveConfiguration, toast, wineryId]
  );

  return (
    <WineryPointOfSaleConfigurationForm
      configuration={configuration}
      deleteAction={deleteAction}
      isDeleting={deleteConfiguration.isLoading}
      isLoading={isLoading || deleteConfiguration.isLoading || saveConfiguration.isLoading}
      isSaving={saveConfiguration.isLoading}
      pointOfSaleSystems={pointOfSaleSystems?.items ?? []}
      serverValidation={saveConfiguration?.error?.validationErrors}
      submitAction={submitAction}
    />
  );
}
